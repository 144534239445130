import * as React from "react";
import { Item } from "semantic-ui-react";
import { FeedCybozuTechBlog } from "../../graphql-types";

interface FeedElement {
  node: FeedCybozuTechBlog;
}
let baseDate = "";
// サイフロ通信のOGPが取得できないため、固定のOGPを設定。サイフロ通信以外の場合は、さぼてっくのiconを設定
const CyFroOgImage = "https://d3t3ozftmdmh3i.cloudfront.net/staging/podcast_uploaded_nologo400/39505250/39505250-1698713093040-8e8742b6a09f6.jpg";
const SabotechOgImage = "https://cybozu.github.io/tech-blog-rss-feed/images/icon.png";
const CybozuTechBlogFeedList: React.FC<Array<FeedElement>> = ({ feeds }) => {
  return (
    <Item.Group link divided relaxed="very">
      {feeds.map((element: FeedElement) => {
      const { id, title, link, isoDate, content, enclosure } = element.node;
      const date = new Date(isoDate);
      const disptitle = title.split('|')[0];
      const dispDate = date.toLocaleString().split(' ')[0];
      const dispPublishDate = dispDate !== baseDate;
      baseDate = dispPublishDate ? dispDate : baseDate;
      // サイボウズTech系ブログのOGPが取得できない場合にBlogs画面が表示できなくなるため、固定のOGPを設定
      const dispOgImage = enclosure ? enclosure.url : link.startsWith("https://podcasters.spotify.com/pod/show/cybozu-frontend/") ? CyFroOgImage : SabotechOgImage;
      return (
        <>
        {dispPublishDate ? <h3 className="tech-blog-feeds">{dispDate}</h3> : null }
        <Item key={id} href={link} target="_blank" rel="noreferrer noopener" className="tech-blog-feeds-item">
          <Item.Image className="mobile hidden" size="small" src={dispOgImage}></Item.Image>
          <Item.Content>
            <Item.Header>{disptitle}</Item.Header>
            <Item.Description>{content.encoded.replace(/(<([^>]+)>)/ig, "").slice(0, 100)}...</Item.Description>
          </Item.Content>
        </Item>
        </>
      );
    })}
    </Item.Group>
  );
};

export default CybozuTechBlogFeedList;
